<template>
  <vs-prompt
      title="Aktueller Stand in der Mittagpausen Kasse"
      @close="$emit('close')"
      @cancel="$emit('close')"
      @accept="$emit('close')"
      cancelText="Schließen"
      :active.sync="activePrompt">

      <div class="bg-gray p-2">
          <vs-row >
              <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="5">
                  <span>Name</span>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="4">
                  Balance
              </vs-col>
              <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="3">
                 Letzte Trans.
              </vs-col>
          </vs-row>
      </div>

    <div v-for="(employee, index) in employees" >

      <vs-row v-if="employee.user_name && employee.current_balance && employee.last_transaction_date" class="p-2">
        <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="5">
          <span>{{ employee.user_name }}:</span>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="4">
          <b v-if="!isNegativeBalance(employee.current_balance)" >{{ employee.current_balance | currency }}</b>
          <b v-else class="text-danger">{{ employee.current_balance | currency }}</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="3">
            {{ formatDateTime(employee.last_transaction_date) }}
        </vs-col>
      </vs-row>
    </div>

  </vs-prompt>
</template>

<script>
import ApiService from "../../api";
import moment from 'moment';
import {mapGetters} from "vuex";
import * as qs from "qs";

export default {
  name: "EmployeeLunchbreakOverviewPopup",
  components: {moment},
  props: [
    'active'
  ],
  data() {
    return {
      activePrompt: false,
      employees: [],
    }
  },
  computed: {
    ...mapGetters(['userId','workspace', 'userHasPermission']),

  },
  created() {
    this.fetchUsers()
  },
  methods: {
    fetchUsers() {
        let queryParams = {
            filter: [
                {
                    field: 'typ',
                    expression: 'exact',
                    value: 'employee'
                }
            ],
        };

        this.$vs.loading()
        ApiService.get('lunchbreak-cash-desk/balance-overview',{
            params: queryParams,
            paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})}).then(response => {
            this.employees = response.data.result;
            this.$vs.loading.close()
        }).catch(response => {
            this.$vs.loading.close()
            this.$vs.notify({
                title: 'Error',
                text: response.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            })
        })

    },
    formatDateTime(value) {
        if (!value) return '-'
        return moment(new Date(value)).format('DD.MM.YYYY')
    },
    activeChange(value) {
      this.activePrompt = value;
      if (value) {
        this.fetchUsers()
      }
    },
      isNegativeBalance(value) {
          return value < 0;
      }

  },
  watch: {
    active: 'activeChange'
  }
}
</script>

<style lang="scss" scoped>
  .bg-gray {
    background-color: #e0e0e0;
  }
</style>