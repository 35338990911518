<template>
  <vs-prompt
      @accept="acceptEmployeeBilling"
      title="Mitarbeiter Abrechnung"
      @close="$emit('close')"

      cancelText="Abbrechen"
      acceptText="Abrechnung vornehmen"
      :active.sync="activePrompt">

      <vs-select v-model="transaction_type" label="Transaction Type" class="w-full mb-3" :autocomplete="false" @change="presetNote()">
        <vs-select-item value="Withdrawal" text="Abbuchung" ></vs-select-item>
        <vs-select-item value="Deposit" text="Einbuchung" ></vs-select-item>
      </vs-select>

      <vs-input name="transfer_notice" v-model="note" class="w-full mb-3" data-vv-as="Verwendungszweck" icon="description" icon-after="true" autocomplete="off"  />

    <div v-for="(employee, index) in employees">

      <vs-row v-if="employee.detail && employee.active === 1" class="mb-1">
        <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="6">
          <span>{{ employee.detail.first_name }} {{ employee.detail.last_name }}:</span>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="6">
          <vs-input name="sum" v-model="employee.sum" class="w-full" data-vv-as="Summe" icon="euro_symbol" icon-after="true" autocomplete="off" />
        </vs-col>
      </vs-row>
    </div>

  </vs-prompt>
</template>

<script>
import ApiService from "../../api";
import moment from 'moment';
import {mapGetters} from "vuex";
import * as qs from "qs";

export default {
  name: "EmployeeLunchbreakAccountingPopup",
  components: {moment},
  props: [
    'active'
  ],
  data() {
    return {
      transaction_type: 'Withdrawal',
      note: '',
      activePrompt: false,
      transfer_notice: "",
      employees: [],
    }
  },
  computed: {
    ...mapGetters(['userId','workspace', 'userHasPermission']),
  },
  created() {
    this.fetchUsers()
    this.presetNote()
  },
  methods: {
    fetchUsers() {

        let queryParams = {
            filter: [
                {
                    expression: 'group',
                    filter: [
                        {
                            field: 'workspace_id',
                            expression: 'exact',
                            value: '1',
                            operator: 'or',
                        },
                        {
                            field: 'workspace_id',
                            expression: 'exact',
                            value: '2',
                            operator: 'or',
                        }
                    ]
                },
                {
                    field: 'typ',
                    expression: 'exact',
                    value: 'employee'
                }
            ],
        };

        this.$vs.loading()
        ApiService.get('users',{
            params: queryParams,
            paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})}).then(response => {
            this.employees = response.data.result;
            this.$vs.loading.close()
        }).catch(response => {
            this.$vs.loading.close()
            this.$vs.notify({
                title: 'Error',
                text: response.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            })
        })

    },
    activeChange(value) {
      this.activePrompt = value;
    },
    presetNote() {
      if(this.transaction_type === 'Withdrawal') {
        this.note = 'Abbuchung durch Mittagspause vom ' + moment().format('DD.MM.YYYY')
      } else {
        this.note = 'Aufladung per Paypal vom ' + moment().format('DD.MM.YYYY')
      }
    },
    acceptEmployeeBilling() {
      if(!this.employees || !this.transaction_type || !this.note) {
        this.$vs.notify({
          title: 'Error',
          text: "Daten fehlen",
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
          return;
      }

      let data = {
        "transaction_type": this.transaction_type,
        "note": this.note,
        "employees": this.employees
      }

      ApiService.post('lunchbreak-cash-desk/add-employee-list', data).then((response) => {
          this.$emit('close', true)
          this.$emit('accept');
        this.$vs.loading.close()

        if(response.data.status === 'success') {

          return this.$vs.notify({
            text: 'Abrechnung erfolgreich',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }
      }).catch((response) => {



        this.$vs.notify({
          title: 'Error',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })


    }
  },
  watch: {
    active: 'activeChange'
  }
}
</script>

<style lang="scss">
.vs-tooltip {
  z-index: 7000000;
}
.error-icon {
  top: 0px;
  position: relative;
  margin-left: 5px;
  color: #e5260e;
  font-size: 18px;
}
</style>