<template>
    <div>
        <vx-card>
            <vue-bootstrap4-table :rows="data" :columns="columns" :config="config" :actions="actions"
                                  @on-change-query="onChangeQuery"
                                  @on-create-lunchbreak-accounting="onClickCreateLunchbreakAccounting"
                                  @on-show-all-balances="onClickShowAllBalances"
                                  columnSelection>


                <template slot="row_actions" slot-scope="props">
                    <div>
                        <vx-tooltip text="Bearbeiten" class="w-1" position="left">
                            <vs-button color="warning" type="filled" icon="edit" size="small"
                                       @click="onClickEditReimbursement(props.row)"></vs-button>
                        </vx-tooltip>
                    </div>
                    <div>
                        <vx-tooltip text="Erledigt - an Personalabrechnung übergeben" class="w-1" position="left">
                            <vs-button color="success" type="filled" icon="check" size="small"
                                       @click="markAsTransferred(props.row)"></vs-button>
                        </vx-tooltip>
                    </div>
                    <div>
                        <vx-tooltip text="Löschen" class="w-1" position="left">
                            <vs-button color="danger" type="filled" icon="delete" size="small"
                                       @click="onClickDelete(props.row)"></vs-button>
                        </vx-tooltip>
                    </div>
                </template>
                <template slot="sum" slot-scope="props">
                    <b>{{ parseCurrency(props.row.sum) }} €</b>
                </template>

                <template slot="username" slot-scope="props">
                  <span class="vbt-table-td">
                    <div>
                      <b>{{ props.row.user.name }}</b><br>
                    </div>
                  </span>

                </template>
                <template slot="cumulative_balance" slot-scope="props">
                    <b>{{ parseCurrency(props.row.cumulative_balance) }} €</b>
                </template>
                <template slot="transaction_type" slot-scope="props">
                    <span :class="transactionClassName(props.row.transaction_type)">{{ props.row.transaction_type }}</span>
                </template>

                <template slot="empty-results">
                    <div class="text-center">
                        <h3>Keine Daten gefunden</h3>
                    </div>
                </template>
            </vue-bootstrap4-table>
        </vx-card>

        <employee-lunchbreak-accounting-popup :active="openAccountingModalActive" @close="closeAccountingModal"></employee-lunchbreak-accounting-popup>
        <employee-lunchbreak-overview-popup  :active="openBalanceOverviewModalActive" @close="closeBalanceOverviewModal"></employee-lunchbreak-overview-popup>

    </div>
</template>

<script>
import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

import ApiService from '../../../api';

import moment from 'moment';
import qs from "qs";
import {mapGetters} from "vuex";
import QueryHelper from "@/mixins/helper/query.helper";
import PriceHelper from "@/mixins/helper/price.helper";
import MysqlSelectionPopup from "../../../components/mysql-connection-popup/MysqlSelectionPopup";
import EmployeeLunchbreakAccountingPopup from "@/components/employee/EmployeeLunchbreakAccountingPopup.vue";
import EmployeeLunchbreakOverviewPopup from "@/components/employee/EmployeeLunchbreakOverviewPopup.vue";

export default {
    components: {
        EmployeeLunchbreakOverviewPopup,
        EmployeeLunchbreakAccountingPopup,
        VueBootstrap4Table,
        MysqlSelectionPopup,
    },
    data() {
        return {
            showFinishedData: false,
            openAccountingModalActive: false,
            openBalanceOverviewModalActive: false,
            selectedReimbursement: [],
            activeMysqlSelectionPrompt: false,
            modalPersonalBilling: false,
            data: [],
            actions: [

                {
                    btn_text: "Abrechnung erstellen",
                    event_name: "on-create-lunchbreak-accounting",
                    color: 'success'
                },
                {
                    btn_text: "Alle Salden anzeigen",
                    event_name: "on-show-all-balances",
                    color: 'dark'
                },
            ],
            columns: [
                {
                    label: "ID",
                    name: "id",
                    filter: {
                        type: "simple",
                        placeholder: "ID suchen..."
                    },
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    width: '3%',
                    showCol: false,
                },
                {
                    label: "Mitarbeiter",
                    name: "user.detail.first_name",
                    slot_name: "username",
                    filter: {
                        type: "simple",
                        placeholder: "Name suchen..."
                    },

                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                },
                {
                    label: "Summe",
                    name: "sum",
                    slot_name: "sum",

                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    formatter: PriceHelper.formatCurrency,
                    showCol: true,
                },
                {
                    label: "Transaktionstyp",
                    name: "transaction_type",
                    slot_name: "transaction_type",
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                },
                {
                    label: "Vermerk",
                    name: "note",
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                },
                {
                    label: "Kumulativer Saldo",
                    name: "cumulative_balance",
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                    width: '15%',
                },
                {
                    label: "Erstellt am",
                    name: "created_at",
                    formatter: this.formatDateTime,
                    filter: {
                        type: 'date',
                        mode: 'range'
                    },
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                },
            ],
            config: {
                checkbox_rows: false,
                rows_selectable: false,
                page: 1,
                per_page: 50,
                show_refresh_button: false,
                show_reset_button: false,
                highlight_row_hover_color: '#f8f8f8',
                global_search:  {
                    visibility:  false,
                },
                server_mode: true
            },
            queryParams: {
                sort: [],
                filters: [],
                global_search: "",
                per_page: 10,
                page: 1,
            },

        }
    },
    created() {
        if (!this.userHasPermission('manage_lunchbreak_cash_desk')) {
            this.$vs.notify({
                title: 'Keine Berechtigung',
                text: 'Für diese Seite hast du keine Berechtigung',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            })
            this.$router.push(`/`);
        }

    },
    computed: {
        ...mapGetters(['userHasPermission']),
        requestParams() {
            return QueryHelper.parseRequestParams(this.queryParams);
        }
    },
    methods: {
        parseCurrency(value) {
            return PriceHelper.formatCurrency(value)
        },
        fetchData() {
            this.$vs.loading()

            ApiService.get("lunchbreak-cash-desk",{
                params: this.requestParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            }).then((response) => {
                this.data = response.data.result;
                this.$vs.loading.close();
            }).catch((response) => {
                this.$vs.loading.close();
                this.$vs.notify({
                    title: 'Fehler',
                    text: response.message,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            });
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            } else {
                return "";
            }

        }, formatDateTime(value) {
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
            } else {
                return "";
            }

        },
        transactionClassName(value) {
            if (value === 'Deposit') {
                return 'badge badge-success'
            } else if (value === 'Withdrawal') {
                return 'badge badge-danger'
            } else {
                return 'badge badge-dark'
            }
        },

        onClickCreateLunchbreakAccounting() {
            this.openAccountingModalActive = true
        },
        onClickShowAllBalances() {
            this.openBalanceOverviewModalActive = true
        },

        closeAccountingModal(saved = false) {
            this.openAccountingModalActive = false;
            if (saved) {
                this.fetchData()
            }

        },
        closeBalanceOverviewModal() {
            this.openBalanceOverviewModalActive = false;

        },
        onChangeQuery(queryParams) {
            this.queryParams = queryParams;
            this.fetchData();
        },

    },
    mounted() {
    }
}

</script>

<style lang="scss">
.input-select-validate-success .input-select-con .input-select {
  color: #71c73f;
  font-weight: 600;
}

.input-select-validate-danger .input-select-con .input-select {
  color: #ea3540;
  font-weight: 600;
}


span.badge {
  background: #999999;
  color: #000;
  padding: 10px 15px;
  border-radius: 29px;
  font-weight: 600;
  font-size: 12px;
  margin-left: 10px;
  text-align: center;
  border: 1px solid #797979;

  &.badge-success {
    background: #61af33;
    color: #fff;
    border: 1px solid #457e22;
  }

  &.badge-danger {
    background: #ea3540;
    color: #fff;
    border: 1px solid #b92c34;
  }

  &.badge-dark {
    background: #999999;
    color: #fff;
    border: 1px solid #797979;
  }
}
</style>
